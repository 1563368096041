/* Set text size to 0.5 times the root element's font size */
.ts-rem-1 {
  font-size: 1rem;
}


/* Set text size to 0.5 times the root element's font size */
.ts-em {
  font-size: 1em;

}

/* Set text size to 5% of the viewport width */
.ts-vw {
  font-size: 5vw;
}


/* Set text size to 10% of the viewport height */
.ts-vh {
  font-size: 10vh;
}

.hide-extra-data {
  margin-bottom: 60px;
  overflow: hidden;
  overflow-x: scroll;
}

.bg-img {
  background-image: url('../images//store-bg.avif');
  background-size: cover;

  width: 100%;
  height: 100%;
  
  position: fixed;

}